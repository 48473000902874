.App {
  text-align: center;
}

.workoutHeaderBox {
  flex-direction: row;
  justify-content: start;
  align-items: center;
  display: flex;
}

.workoutHeader {
  color: #00f;
  font-family: Indie Flower, Cochin, Georgia, Times, Times New Roman, serif;
  font-size: 16pt;
  font-weight: bold;
}

.test {
  background-color: #f0f8ff;
}

.mainGrid {
  display: flex;
}

.workoutContainer {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.leftPanel {
  height: 100vh;
}

.modal {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0006;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  width: 80%;
  background-color: #fefefe;
  border: 1px solid #888;
  margin: 2% auto;
  padding: 20px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.elementSelector {
  padding: 2px;
}

.elementPicker {
  display: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000;
}

.elementPickerHeaderColumn {
  width: 120px;
}

.elementPickerHeader {
  padding: 0;
}

.elementPickerColumn {
  vertical-align: top;
  background: linear-gradient(#0000001a, #00000080);
  padding: 20px;
}

.elementContainer {
  flex-direction: column;
  display: flex;
}

.elementContainerRow {
  width: 100%;
  display: flex;
}

.elementContainerColumn {
  width: 180px;
  vertical-align: top;
  background: linear-gradient(#0000001a, #00000080);
  border: 3px solid purple;
  padding: 20px;
}

.elementList {
  background-color: (255, 255, 255, .5);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.selectedElement {
  color: #fff;
  background: #5073e9;
}

.element {
  height: 20px;
  cursor: pointer;
  user-select: none;
  border: 1px solid #aaa;
  border-radius: 8px;
  margin: 2px;
  padding-left: 10px;
  font-family: Roboto, sans-serif;
  font-size: 10pt;
  line-height: 20px;
  position: relative;
  box-shadow: 2px 2px 3px #666;
}

.normalElement {
  background-color: #fff;
}

li.element:active {
  color: #fff;
  background-color: #eee;
  box-shadow: inset 0 1px 1px #666;
}

.wrapper {
  width: 500px;
  display: flex;
}

.wrapper > div {
  flex: 150px;
}

.attributeEditor {
  display: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000;
}

.attributeEditorCol {
  width: 120px;
  vertical-align: top;
  padding: 20px;
}

.attributeEditorHeader {
  padding: 0;
}

.styleList {
  width: 100%;
  height: 100%;
  margin: 2px;
  display: flex;
}

.styleList > ul {
  flex: 1;
  list-style-type: none;
}

.styleList > ul > li {
  font-family: Verdana;
  font-size: 14px;
}

.tightWrapper {
  width: 430px;
  display: flex;
}

.tightWrapper > div {
  flex: 1;
}

.attributeWrapper {
  width: 275px;
  display: flex;
}

.attributeWrapper > div {
  flex: 80px;
}

.formLabel {
  width: 120px;
  text-align: right;
  margin-right: 15px;
  font-family: Roboto, sans-serif;
  font-size: 10pt;
  display: inline-block;
}

.formLabel:after {
  content: ":";
}

.formListItem {
  margin-top: 10px;
}

.settings {
  grid-gap: 5px;
  grid-template-columns: 160px 1fr;
  display: grid;
}

div.settings label {
  width: 150px;
  text-align: right;
}

div.settings label:after {
  content: ":";
}

.helpPanel {
  text-align: left;
  background-color: #add8e6;
  border: 1px solid #000;
  margin: 0;
  padding: 1px;
}

.helpPanel > ul {
  list-style-type: none;
}

.toggled {
  font-style: bold;
  background-color: #add8e6;
}

.borderedContainer {
  border: 1px solid #000;
  padding: 5px;
}

body.reflex-col-resize {
  cursor: col-resize;
}

body.reflex-row-resize {
  cursor: row-resize;
}

.reflex-container {
  height: 100%;
  width: 100%;
  place-content: stretch flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.reflex-container.horizontal {
  min-height: 1px;
  flex-direction: column;
}

.reflex-container.vertical {
  min-width: 1px;
  flex-direction: row;
}

.reflex-container > .reflex-element {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
}

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  user-select: none;
}

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%;
}

.reflex-container > .reflex-splitter {
  z-index: 100;
  background-color: #eee;
}

.reflex-container > .reflex-splitter.active, .reflex-container > .reflex-splitter:hover {
  background-color: #c6c6c6;
  transition: all 1s;
}

.horizontal > .reflex-splitter {
  cursor: row-resize;
  width: 100%;
  height: 2px;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6;
}

.reflex-element.horizontal .reflex-handle {
  cursor: row-resize;
  user-select: none;
}

.reflex-container.horizontal > .reflex-splitter:hover, .reflex-container.horizontal > .reflex-splitter.active {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.reflex-container.vertical > .reflex-splitter {
  cursor: col-resize;
  height: 100%;
  width: 2px;
  border-left: 1px solid #c6c6c6;
  border-right: 1px solid #c6c6c6;
}

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  user-select: none;
}

.reflex-container.vertical > .reflex-splitter:hover, .reflex-container.vertical > .reflex-splitter.active {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

.reflex-container > .reflex-splitter.reflex-thin {
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  opacity: .2;
  z-index: 100;
  background-clip: padding-box;
}

.reflex-container > .reflex-splitter.reflex-thin.active .reflex-container > .reflex-splitter.reflex-thin:hover {
  opacity: .5;
  transition: all 1.5s;
}

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  cursor: row-resize;
  width: 100%;
  border-top: 8px solid #fff0;
  border-bottom: 8px solid #fff0;
  margin: -8px 0;
  height: 17px !important;
}

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active, .reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-top: 8px solid #e4e4e4;
  border-bottom: 8px solid #e4e4e4;
}

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  cursor: col-resize;
  height: 100%;
  border-left: 8px solid #fff0;
  border-right: 8px solid #fff0;
  margin: 0 -8px;
  width: 17px !important;
}

.reflex-container.vertical > .reflex-splitter.reflex-thin.active, .reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-left: 8px solid #e4e4e4;
  border-right: 8px solid #e4e4e4;
}

/*# sourceMappingURL=index.a5cc9b21.css.map */
